<template>
  <div id="champions-page" class="container content-binder">
    <template v-if="error"><error-placeholder></error-placeholder></template>
    <template v-else>
      <champions-overview
        :wins="wins"
        :gold="gold"
        :own-rank="ownRank"
        :matches-played="userBattles.length"
        :open-matches="maxBattles - userBattles.length"
        :best-win="bestWin"
        :best-match-number="battleNumber"
        :current-rank-reward="currentRankReward"
      ></champions-overview>
      <champions-ranking
        :ranking="ranking"
        :rewards="rewards"
        :history="history"
      ></champions-ranking>
      <champions-history></champions-history
    ></template>
    <!--    <modal-champions v-if="isModalActive" />-->
  </div>
</template>

<script>
import ChampionsOverview from "@/components/champions/champions-overview";
import ChampionsRanking from "@/components/champions/champions-ranking";
import ChampionsHistory from "@/components/champions/champions-history";
import ErrorPlaceholder from "@/components/base/error-placeholder";
import * as ChampionsAPI from "@/API/champions.js";
// import ModalChampions from "@/components/champions/modal-champions";

export default {
  name: "ChampionsPage",
  components: {
    // ModalChampions,
    ChampionsOverview,
    ChampionsRanking,
    ChampionsHistory,
    ErrorPlaceholder,
  },

  mixins: [],
  data() {
    return {
      ranking: [],
      rewards: [],
      history: [],
      wins: 0,
      gold: 0,
      ownRank: 0,
      currentRankReward: null,
      maxBattles: 0,
      bestWin: 0,
      battleNumber: 1,
      error: false,
    };
  },
  computed: {
    userBattles() {
      return this.$store.state.champions.userBattles;
    },

    isModalActive() {
      let lastActive = this.$store.getters["champions/getModalLastShown"];
      if (!lastActive) {
        return true;
      }

      let today = new Date();
      let last = new Date(lastActive);
      return today.getDate() !== last.getDate();
    },
  },
  created() {
    this.$root.$on("update-matchday", () => {
      this.getMatchday();
    });
  },
  mounted() {
    this.$store.commit("champions/deactivatePulsatingMenuItem");

    Promise.all([
      this.getBattle(),
      this.getMatchday(),
      this.getRanking(),
      this.getUserBattles(),
      this.getRewards(),
      this.getRankingHistory(),
      this.getConfig(),
    ])

      .catch((e) => {
        this.error = true;
        console.log(e);
      })
      .finally(() => {
        this.getCurrentRankRewards();
        this.$store.commit("setIsPageLoading", false);
      });
  },

  methods: {
    getCurrentRankRewards() {
      if (this.ownRank < 0 || this.ownRank > this.rewards.length) {
        return;
      }
      this.currentRankReward = this.rewards[this.ownRank - 1];
    },
    getBattle() {
      return new Promise((resolve, reject) => {
        ChampionsAPI.fetchBattle()
          .then((res) => {
            let currentBattle = {
              ...this.$store.getters["champions/getCurrentBattle"],
            };

            //battle still active. just update some values
            if (
              res.battle &&
              currentBattle &&
              currentBattle.id === res.battle.id
            ) {
              currentBattle.ended_at = res.battle.ended_at;
              currentBattle.win = res.battle.win;
              currentBattle.battle = res.battle.battle;
              this.$store.commit("champions/setCurrentBattle", currentBattle);
              resolve(res);
            } else {
              this.$store.commit("champions/setCurrentBattle", res.battle);
            }
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    getMatchday() {
      return new Promise((resolve, reject) => {
        ChampionsAPI.fetchMatchday()
          .then((res) => {
            this.$store.commit(
              "champions/setCurrentMatchday",
              res.battle_matchday
            );

            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    getRanking() {
      return new Promise((resolve, reject) => {
        ChampionsAPI.getRanking()
          .then((res) => {
            this.ranking = res.battle_ranking;
            this.determineOwnRank();

            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    getUserBattles() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("champions/getUserBattles")
          .then((res) => {
            this.calculateCompletedBattles(res);
            if (res.length > 0) {
              this.calculateOverviewData(res);
            }

            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    getRewards() {
      return new Promise((resolve, reject) => {
        ChampionsAPI.fetchRewards()
          .then((res) => {
            this.rewards = res.battle_rewards;

            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    getConfig() {
      return new Promise((resolve, reject) => {
        ChampionsAPI.getConfig()
          .then((res) => {
            this.maxBattles = res.user_battles;

            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    getRankingHistory() {
      return new Promise((resolve, reject) => {
        ChampionsAPI.getRankingHistory()
          .then((res) => {
            this.history = res.battle_ranking_history;

            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    calculateOverviewData(battlesArray) {
      let winCounter = 0;
      let goldSum = 0;
      let highestGold = 0;
      let highestIndex = 0;
      battlesArray.forEach((battle, index) => {
        goldSum += battle.gold;
        if (battle.gold > highestGold) {
          highestGold = battle.gold;
          highestIndex = index;
        }
        if (battle.win) {
          winCounter++;
        }
      });
      this.wins = winCounter;
      this.gold = goldSum;
      this.bestWin = highestGold;
      this.battleNumber = highestIndex + 1;
    },

    calculateCompletedBattles(battlesArray) {
      let battleCounter = battlesArray.length;

      if (battleCounter > 0) {
        if (!battlesArray[battlesArray.length - 1].ended_at) {
          battleCounter--;
        }
      }

      this.$store.commit("champions/setCompleteBattleAmount", battleCounter);
    },

    determineOwnRank() {
      let rank = this.ranking.findIndex(
        (user) => user.user_id === this.$store.getters["user/currentUser"].id
      );
      if (rank < 0) {
        return 0;
      } else {
        this.ownRank = rank + 1;
      }
    },
  },
};
</script>
<style lang="scss">
#champions-page {
  max-width: 1300px;

  .light-yellow {
    color: #fff8b7;
  }
  .ranking-table-headline {
    background-color: $dark-blue-03;
    height: 55px;
  }
  .glow {
    text-shadow: 0 0 14px rgba(77, 229, 255, 0.6),
      0 0 7px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
  }
  .gold-coin {
    height: 16px;
    width: 16px;
    background-image: url(~@/assets/img/common/coin1.png);
    margin-left: 11px;
  }
  .crown {
    width: 26px;
    height: 19px;
    background-image: url(~@/assets/img/common/crown6.png);
    margin-left: 11px;
  }
}
</style>
