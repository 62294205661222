var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"id":"champions-history"}},[_c('b-col',{staticClass:"ranking-table-headline d-inline-flex align-items-center justify-content-start position-relative",attrs:{"cols":"12"}},[_c('div',{staticClass:"uppercase-subheadline"},[_vm._v(" "+_vm._s(_vm.$t("champions.user_history.subheadline"))+" ")]),_c('InfoBadge',{attrs:{"id":"info-champions-history","info-text":_vm.$t('info_badge.champions.user_battles')}})],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.userBattles.length > 0)?_c('b-table',{attrs:{"id":"champions-history-table","items":_vm.mappedBattles,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_c('span',{staticClass:"medium text-normal"},[_vm._v(_vm._s(_vm.calculatedRank(data)))])]}},{key:"cell(started_at)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.placeholder)?_c('div',[_vm._v("--")]):(value)?_c('div',{staticClass:"position-relative"},[(_vm.isNewEntry(item))?_c('div',{staticClass:"green-dot position-absolute"}):_vm._e(),_c('span',{staticClass:"medium text-normal"},[_vm._v(" "+_vm._s(_vm.matchTime(value)))])]):_vm._e()]}},{key:"cell(win)",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.handleRowClick(item)}}},[(item.placeholder)?_c('div',[_vm._v("--")]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.winText(item))}})])]}},{key:"cell(gold)",fn:function(ref){
var item = ref.item;
return [(item.placeholder)?_c('div',{staticClass:"text-right mr-3"},[_vm._v("--")]):_c('div',{staticClass:"d-inline-flex align-items-center justify-content-end w-100"},[_c('span',{staticClass:"text-white bold text-normal glow"},[_vm._v(_vm._s(_vm.getOwnGold(item)))]),_c('div',{staticClass:"gold-coin bg-img"})])]}},{key:"cell(opponent_name)",fn:function(ref){
var item = ref.item;
return [(item.placeholder)?_c('div',{staticClass:"d-inline-flex align-items-center justify-content-center w-100"},[_vm._v(" -- ")]):_c('div',{staticClass:"d-inline-flex align-items-center justify-content-center w-100",on:{"click":function($event){_vm.openProfile(_vm.getOpponentId(item))}}},[_vm._v(" "+_vm._s(_vm.shortenString(_vm.getOpponentName(item)))+" ")])]}},{key:"cell(gold_opponent)",fn:function(ref){
var item = ref.item;
return [(item.placeholder)?_c('div',{staticClass:"text-right mr-3"},[_vm._v("--")]):_c('div',{staticClass:"d-inline-flex align-items-center justify-content-end w-100"},[_c('span',{staticClass:"text-white bold text-normal glow"},[_vm._v(_vm._s(_vm.getGoldOpponent(item)))]),_c('div',{staticClass:"gold-coin bg-img"})])]}}],null,false,1826204558)}):_c('div',{staticClass:"mt-3 text-center light-yellow text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("champions.user_history.placeholder"))+" ")]),_c('tablePagination',{attrs:{"per-page":_vm.perPage,"total-rows":_vm.userBattles.length},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }