<template>
  <b-row id="champions-overview" no-gutters align-h="center"
    ><b-col cols="12" class="d-inline-flex align-items-center"
      ><p class="uppercase-subheadline m-0">
        {{ $t("champions.overview.subheadline") }}
      </p>
      <InfoBadge
        id="info-champions-overview"
        :info-text="$t('info_badge.champions.overview')" /></b-col
    ><b-col cols="6" md="12" xl="6" class="pr-xl-3">
      <div class="overview-box drop-shadow-box">
        <b-row class="h-100 ch-row align-items-center justify-content-center"
          ><b-col
            cols="5"
            class="h-100 d-flex flex-column align-items-center justify-content-around"
            ><div class="avatar-field">
              <img :src="$store.getters['user/currentUser'].avatar_url" />
            </div>
            <span class="light-yellow text-uppercase bold">{{
              shortenString($store.getters["user/currentUser"].name, 20)
            }}</span></b-col
          ><b-col
            cols="7"
            class="h-100 d-flex flex-column justify-content-around"
            ><div>
              <div class="w-100">
                <p
                  class="light-yellow bold text-uppercase"
                  style="font-size: 24px"
                >
                  {{ $t("champions.current_week") }}
                </p>
              </div>
              <div class="w-100 mt-2 d-inline-flex align-items-center">
                <div class="d-flex flex-column mr-3">
                  <div class="grey-small text-uppercase">
                    {{ $t("champions.wins") }}
                  </div>
                  <div class="text-white bold">{{ wins }}</div>
                </div>
                <div class="d-flex flex-column">
                  <div class="grey-small text-uppercase">
                    {{ $t("champions.gold_sum") }}
                  </div>
                  <div class="d-inline-flex align-items-center">
                    <span class="text-white bold">{{
                      formatNumber(gold)
                    }}</span>
                    <img
                      draggable="false"
                      class="ml-3"
                      src="@/assets/img/common/coin1.png"
                      style="width: 21px; height: 21px"
                    />
                  </div>
                </div>
              </div>
              <div class="w-100 mt-2 d-inline-flex align-items-center">
                <div class="d-flex flex-column mr-3">
                  <div class="grey-small medium text-uppercase">
                    {{ $t("champions.rank") }}
                  </div>
                  <div class="text-white bold">
                    <template v-if="ownRank === 0 || ownRank > 100">--</template
                    ><template v-else>{{ ownRank }}</template>
                  </div>
                </div>

                <div class="d-flex flex-column mr-3">
                  <div class="grey-small medium text-uppercase">
                    {{ $t("champions.rewards_single") }}
                  </div>
                  <div class="text-white bold">
                    <template v-if="ownRank === 0 || !currentRankReward"
                      >--</template
                    ><template v-else>
                      <div
                        class="d-inline-flex align-items-center justify-content-end w-100"
                      >
                        <span class="text-white bold text-normal glow">{{
                          formatNumber(currentRankReward[0].amount)
                        }}</span>
                        <div class="gold-coin bg-img"></div>

                        <span class="text-white bold text-normal glow ml-4">{{
                          formatNumber(currentRankReward[1].amount)
                        }}</span>
                        <div class="crown bg-img"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div class="w-100 mt-2 d-inline-flex align-items-center">
                <div class="d-flex flex-column mr-3">
                  <div class="grey-small medium text-uppercase">
                    {{ $t("champions.matches_played") }}
                  </div>
                  <div class="text-white bold">{{ matchesPlayed }}</div>
                </div>

                <div class="d-flex flex-column m-3">
                  <div class="grey-small medium text-uppercase">
                    {{ $t("champions.overview.matches_open") }}
                  </div>
                  <div class="text-white bold">{{ openMatches }}</div>
                </div>
              </div>
            </div>
          </b-col></b-row
        >
      </div> </b-col
    ><b-col
      v-if="matchesPlayed"
      cols="6"
      md="12"
      xl="6"
      class="mt-md-5 mt-xl-0 pl-xl-3"
    >
      <div class="overview-box gold-border-box position-relative">
        <div class="w-100 text-center">
          <p
            class="light-yellow bold text-uppercase head"
            style="font-size: 24px"
          >
            {{ $t("champions.overview.best_match") }}
          </p>
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <img
            v-if="bestWin > 0"
            draggable="false"
            src="@/assets/img/champions/kk-champions-wreath.png"
            class="wreath left"
          />
          <div class="d-flex flex-column align-items-center">
            <div class="text-white bold medium text-uppercase mb-3">
              {{ $t("champions.match_number", { 0: bestMatchNumber }) }}
            </div>
            <div
              class="w-100 d-inline-flex align-items-center justify-content-around"
            >
              <div class="d-flex flex-column align-items-center">
                <div class="grey-small grey-bigger medium text-uppercase">
                  {{ $t("champions.overview.best_gold") }}
                </div>
                <div class="d-inline-flex align-items-center">
                  <span class="text-white bold bigger">{{
                    formatNumber(bestWin)
                  }}</span>
                  <img
                    draggable="false"
                    class="ml-3"
                    src="@/assets/img/common/coin1.png"
                    style="width: 28px; height: 28px"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            v-if="bestWin > 0"
            draggable="false"
            src="@/assets/img/champions/kk-champions-wreath.png"
            class="wreath"
          />
        </div>
      </div> </b-col
  ></b-row>
</template>
<script>
import InfoBadge from "@/components/base/info-badge.vue";
export default {
  name: "ChampionsOverview",
  components: { InfoBadge },
  props: {
    wins: {
      type: Number,
      required: false,
      default: 0,
    },
    gold: {
      type: Number,
      required: false,
      default: 0,
    },
    ownRank: {
      type: Number,
      required: false,
      default: 0,
    },
    matchesPlayed: {
      type: Number,
      required: false,
      default: 0,
    },
    openMatches: {
      type: Number,
      required: false,
      default: 0,
    },
    bestWin: {
      type: Number,
      required: false,
      default: 0,
    },
    bestMatchNumber: {
      type: Number,
      required: false,
      default: 1,
    },
    currentRankReward: {
      type: Array,
      required: false,
      default: null,
    },
  },
};
</script>
<style lang="scss">
#champions-overview {
  .info-badge-orange {
    position: relative;
    margin-left: 1rem;
  }
  .overview-box {
    min-height: 247px;

    .ch-row {
      min-height: 231px;
    }

    .head {
      padding-top: 2.5%;
    }
    .avatar-field {
      background-color: black;
      border-radius: 9.4px;
      width: 94px;
      height: 94px;
      box-shadow: 0 3px 0 #074f5c;
      img {
        max-width: 100%;
      }
      span {
        font-size: 21px;
      }
    }
    .matchweek-row {
      display: inline-flex;
    }
    .grey-small {
      color: #8e8e8e;
      font-size: 12px;
      &.grey-bigger {
        font-size: 18px;
      }
    }
    .text-white.bold {
      font-size: 21px;
      text-shadow: 0 0 14px rgba(77, 229, 255, 0.6),
        0 0 7px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
      &.bigger {
        font-size: 32px;
      }
    }
    .wreath {
      width: 95px;
      height: 156px;
      &.left {
        transform: rotateY(180deg);
      }
    }
  }
}
</style>
