<template>
  <b-row id="champions-history"
    ><b-col
      cols="12"
      class="ranking-table-headline d-inline-flex align-items-center justify-content-start position-relative"
      ><div class="uppercase-subheadline">
        {{ $t("champions.user_history.subheadline") }}
      </div>
      <InfoBadge
        id="info-champions-history"
        :info-text="$t('info_badge.champions.user_battles')"
    /></b-col>

    <b-col cols="12">
      <b-table
        v-if="userBattles.length > 0"
        id="champions-history-table"
        :items="mappedBattles"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:cell(index)="data">
          <span class="medium text-normal">{{ calculatedRank(data) }}</span>
        </template>
        <template v-slot:cell(started_at)="{ item, value }">
          <div v-if="item.placeholder">--</div>

          <div v-else-if="value" class="position-relative">
            <div
              v-if="isNewEntry(item)"
              class="green-dot position-absolute"
            ></div>
            <span class="medium text-normal"> {{ matchTime(value) }}</span>
          </div>
        </template>
        <template v-slot:cell(win)="{ item }">
          <div @click="handleRowClick(item)">
            <div v-if="item.placeholder">--</div>

            <div v-else v-html="winText(item)"></div>
          </div>
        </template>
        <template v-slot:cell(gold)="{ item }">
          <div v-if="item.placeholder" class="text-right mr-3">--</div>

          <div
            v-else
            class="d-inline-flex align-items-center justify-content-end w-100"
          >
            <span class="text-white bold text-normal glow">{{
              getOwnGold(item)
            }}</span>
            <div class="gold-coin bg-img"></div>
          </div>
        </template>
        <template v-slot:cell(opponent_name)="{ item }">
          <div
            v-if="item.placeholder"
            class="d-inline-flex align-items-center justify-content-center w-100"
          >
            --
          </div>
          <div
            v-else
            class="d-inline-flex align-items-center justify-content-center w-100"
            @click="openProfile(getOpponentId(item))"
          >
            {{ shortenString(getOpponentName(item)) }}
          </div>
        </template>
        <template v-slot:cell(gold_opponent)="{ item }">
          <div v-if="item.placeholder" class="text-right mr-3">--</div>
          <div
            v-else
            class="d-inline-flex align-items-center justify-content-end w-100"
          >
            <span class="text-white bold text-normal glow">{{
              getGoldOpponent(item)
            }}</span>
            <div class="gold-coin bg-img"></div>
          </div>
        </template>
      </b-table>
      <div v-else class="mt-3 text-center light-yellow text-uppercase">
        {{ $t("champions.user_history.placeholder") }}
      </div>
      <tablePagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="userBattles.length"
      ></tablePagination>
    </b-col>
  </b-row>
</template>
<script>
import tablePagination from "@/components/base/pagination.vue";
import InfoBadge from "@/components/base/info-badge.vue";
import * as profileAPI from "@/API/profile";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
export default {
  name: "ChampionsHistory",
  components: { tablePagination, InfoBadge },
  mixins: [getPublicUserInfos],
  props: {},

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: this.$t("champions.user_history.match") },
        {
          key: "started_at",
          label: this.$t("champions.user_history.match_time"),
          class: "started-column",
        },
        { key: "win", label: this.$t("champions.user_history.winner") },
        { key: "gold", label: this.$t("champions.user_history.gold_won") },
        {
          key: "opponent_name",
          label: this.$t("champions.user_history.opponent_name"),
        },
        {
          key: "gold_opponent",
          label: this.$t("champions.user_history.gold_won_opponent"),
        },
      ],
    };
  },
  computed: {
    userBattles() {
      return this.$store.state.champions.userBattles;
    },
    mappedBattles() {
      let arrCopy = this.userBattles.slice();
      if (arrCopy.length % this.perPage === 0 && arrCopy.length > 0) {
        return arrCopy;
      } else {
        while (arrCopy.length % this.perPage !== 0 || arrCopy.length === 0) {
          arrCopy.push({ placeholder: true });
        }
        return arrCopy;
      }
    },
  },
  watch: {
    userBattles() {
      if (this.userBattles.length > this.perPage) {
        this.currentPage = Math.ceil(this.userBattles.length / this.perPage);
      }
    },
  },

  beforeDestroy() {
    this.$store.commit("champions/clearHistoryEntries");
  },
  methods: {
    openProfile(id) {
      this.fetchPublicUser(id);
    },
    matchTime(dateString) {
      return new Date(dateString).toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    calculatedRank(data) {
      return data.index + 1 + (this.currentPage - 1) * 10;
    },
    winText(battle) {
      if (!battle.ended_at) {
        return (
          "<span class='medium text-normal text-orange'>" +
          this.$t("champions.user_history.keep_playing") +
          "</span>"
        );
      } else if (battle.ended_at && !battle.battle.ended_at) {
        return (
          "<span class='medium text-normal text-orange'>" +
          this.$t("champions.user_history.result_pending") +
          "</span>"
        );
      } else if (battle.win) {
        return (
          "<span class='medium text-normal text-green'>" +
          this.$t("champions.user_history.you_won") +
          "</span>"
        );
      } else
        return (
          "<span class='medium text-normal text-white'>" +
          this.$t("champions.user_history.you_lost") +
          "</span>"
        );
    },
    isNewEntry(battle) {
      return (
        this.$store.state.champions.newHistoryEntries.indexOf(battle.id) >= 0
      );
    },

    getOpponentName(battle) {
      if (battle.battle.user_battles.length < 2) {
        return "--";
      } else {
        let opponentBattle = null;
        battle.battle.user_battles.forEach((battle) => {
          if (battle.user_id !== this.$store.getters["user/currentUser"].id) {
            opponentBattle = battle;
          }
        });

        if (opponentBattle) {
          return opponentBattle.user.name;
        } else {
          return "--";
        }
      }
    },
    getOpponentId(battle) {
      if (battle.battle.user_battles.length < 2) {
        return "--";
      } else {
        let opponentBattle = null;
        battle.battle.user_battles.forEach((battle) => {
          if (battle.user_id !== this.$store.getters["user/currentUser"].id) {
            opponentBattle = battle;
          }
        });

        if (opponentBattle) {
          return opponentBattle.user.id;
        } else {
          return "--";
        }
      }
    },

    getGoldOpponent(battle) {
      if (battle.battle.user_battles.length < 2) {
        return "--";
      } else {
        let opponentBattle = null;
        battle.battle.user_battles.forEach((battle) => {
          if (battle.user_id !== this.$store.getters["user/currentUser"].id) {
            opponentBattle = battle;
          }
        });
        if (opponentBattle && opponentBattle.ended_at && battle.ended_at) {
          return this.formatNumber(opponentBattle.gold);
        } else {
          return "--";
        }
      }
    },

    getOwnGold(battle) {
      if (battle.started_at && battle.ended_at) {
        return this.formatNumber(battle.gold);
      } else {
        return "--";
      }
    },
    handleRowClick(row) {
      if (!row.ended_at) {
        this.$router.push(`/champions/game/${row.battle.game_id}`);
      }
    },
  },
};
</script>
<style lang="scss">
#champions-history {
  margin-top: 33px;
  .info-badge-orange {
    position: relative;
    margin-left: 1rem;
  }

  #champions-history-table {
    thead th {
      border: none;
      text-transform: uppercase;
      color: #fff8b7;
      font-family: Ubuntu-bold;
      font-size: 12px;
    }
    .text-green {
      color: $highlight-green;
    }
    .text-orange {
      color: #ffa900;
    }
    .green-dot {
      width: 14px;
      height: 14px;
      background-color: $highlight-green;
      border-radius: 100%;
      bottom: 5px;
      left: 5px;
    }

    .started-column {
      width: 17%;
    }
  }
  #table-pagination {
    right: 0;
  }
}
</style>
