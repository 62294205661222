<template>
  <b-row id="champions-ranking"
    ><b-col
      cols="12"
      class="ranking-table-headline d-inline-flex align-items-center justify-content-between position-relative"
      ><div
        class="d-inline-flex align-items-center justify-content-start position-relative"
      >
        <div class="uppercase-subheadline">
          {{ $t("champions.ranking.subheadline") }}
        </div>
        <InfoBadge
          id="info-champions-ranking"
          :info-text="$t('info_badge.champions.ranking')"
        />
      </div>
      <div
        v-if="history.length > 0"
        class="w-50 d-inline-flex align-items-center justify-content-around"
      >
        <span class="text-uppercase medium text-normal white-shadow">{{
          $t("champions.ranking.show_week")
        }}</span
        ><button
          class="rect-btn"
          :class="{ inactive: !showCurrentWeek }"
          @click="handleCurrentClick"
        >
          {{ $t("champions.current_week") }}</button
        ><button
          class="rect-btn"
          :class="{ inactive: showCurrentWeek }"
          @click="handlePreviousClick"
        >
          {{ $t("champions.previous_week") }}
        </button>
      </div></b-col
    ><b-col cols="12" :class="{ 'pb-5': mappedRanking.length > perPage }">
      <b-table
        v-if="matchdayWeek.length > 0"
        id="champions-ranking-table"
        class="pb-5"
        :items="mappedRanking"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :tbody-tr-class="highlightSelf"
        @row-clicked="openDetailsModal"
      >
        <template v-slot:table-colgroup>
          <col key="index" style="width: 8%" />
          <col key="name" style="width: 25%" />
          <col key="win_sum" style="width: 8%" />
          <col key="level" style="width: 8%" />
          <col key="battle_sum" style="width: 8%" />
        </template>
        <template v-slot:cell(index)="data">
          <span class="medium text-normal highlightable">{{
            calculatedRank(data)
          }}</span>
        </template>
        <template v-slot:head(name)="data">
          <div class="text-left">{{ data.label }}</div>
        </template>
        <template v-slot:cell(name)="{ item, value }">
          <div v-if="item.placeholder" class="text-left">--</div>
          <div v-else class="medium text-normal text-left highlightable">
            <template v-if="!showCurrentWeek">{{
              shortenString(item.user.name, 25)
            }}</template>
            <template v-else> {{ shortenString(value, 25) }}</template>
          </div>
        </template>
        <template v-slot:cell(level)="{ item, value }">
          <div v-if="item.placeholder">--</div>
          <div v-else class="medium text-normal highlightable">
            <template v-if="showCurrentWeek"> {{ value }}</template
            ><template v-else>{{ item.user.level }}</template>
          </div>
        </template>

        <template v-slot:cell(win_sum)="{ item, value }">
          <div v-if="item.placeholder">--</div>
          <div v-else class="medium text-normal highlightable">
            {{ shortenString(value, 25) }}
          </div>
        </template>
        <template v-slot:cell(battle_sum)="{ item, value }">
          <div v-if="item.placeholder">--</div>

          <div v-else class="medium text-normal highlightable">
            {{ shortenString(value, 25) }}
          </div>
        </template>

        <template v-slot:cell(gold_sum)="{ item, value }">
          <div v-if="item.placeholder" class="text-right mr-3">--</div>

          <div
            v-else
            class="d-inline-flex align-items-center justify-content-end w-100"
          >
            <span class="text-white bold text-normal glow">{{
              formatNumber(value)
            }}</span>
            <div class="gold-coin bg-img"></div>
          </div>
        </template>
        <template v-slot:cell(rewards)="data">
          <div v-if="data.item.placeholder" class="text-right mr-3">--</div>
          <div
            v-else-if="rewards && rewards.length"
            class="d-inline-flex align-items-center justify-content-end w-100"
          >
            <span class="text-white bold text-normal glow">{{
              formatNumber(
                rewards[data.index + (currentPage - 1) * perPage].find(
                  (reward) => reward.type === "gold"
                ).amount
              )
            }}</span>
            <div class="gold-coin bg-img"></div>
            <div
              style="min-width: 80px"
              class="d-inline-flex align-items-center justify-content-end"
            >
              <span class="text-white bold text-normal glow ml-3">{{
                formatNumber(
                  rewards[data.index + (currentPage - 1) * perPage].find(
                    (reward) => reward.type === "crown"
                  ).amount
                )
              }}</span>
              <div class="crown bg-img"></div>
            </div>
          </div> </template
      ></b-table>
      <div v-else class="mt-3 text-center light-yellow text-uppercase">
        {{ $t("champions.ranking.placeholder") }}
      </div>
      <tablePagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="mappedRanking.length"
      ></tablePagination> </b-col
  ></b-row>
</template>
<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import InfoBadge from "@/components/base/info-badge.vue";
import tablePagination from "@/components/base/pagination.vue";
export default {
  name: "ChampionsRanking",
  components: { tablePagination, InfoBadge },
  mixins: [getPublicUserInfos],
  props: {
    ranking: {
      type: Array,
      required: true,
      default: () => [],
    },
    rewards: {
      type: Array,
      required: false,
      default: () => [],
    },
    history: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      showCurrentWeek: true,
      fields: [
        { key: "index", label: this.$t("champions.rank") },
        {
          key: "name",
          label: this.$t("champions.player"),
          sortable: false,
        },
        {
          key: "level",
          label: this.$t("profile.level"),
          sortable: true,
        },
        {
          key: "win_sum",
          label: this.$t("champions.wins"),
          sortable: true,
        },
        {
          key: "battle_sum",
          label: this.$t("champions.matches_played"),
          sortable: true,
        },
        {
          key: "gold_sum",
          label: this.$t("champions.gold_sum"),
          sortable: true,
        },
        { key: "rewards", label: this.$t("champions.rewards") },
      ],
    };
  },
  computed: {
    mappedRanking() {
      let arrCopy = this.matchdayWeek.slice();
      if (arrCopy.length % this.perPage === 0 && arrCopy.length > 0) {
        return arrCopy;
      } else {
        while (arrCopy.length % this.perPage !== 0 || arrCopy.length === 0) {
          arrCopy.push({ placeholder: true });
        }
        return arrCopy;
      }
    },

    matchdayWeek() {
      if (this.showCurrentWeek) {
        return this.ranking;
      } else {
        return this.history;
      }
    },
  },
  methods: {
    calculatedRank(data) {
      return data.index + 1 + (this.currentPage - 1) * 10;
    },
    handleCurrentClick() {
      if (this.showCurrentWeek) return;
      this.showCurrentWeek = true;
    },
    handlePreviousClick() {
      if (!this.showCurrentWeek) return;
      this.showCurrentWeek = false;
    },
    highlightSelf(data) {
      if (data.user_id === this.$store.getters["user/currentUser"].id) {
        return "text-green";
      } else {
        return "";
      }
    },
    openDetailsModal(row) {
      this.fetchPublicUser(row.user_id);
    },
  },
};
</script>
<style lang="scss">
#champions-ranking {
  margin-top: 33px;
  .info-badge-orange {
    position: relative;
    margin-left: 1rem;
  }

  #champions-ranking-table {
    thead th {
      border: none;
      text-transform: uppercase;
      color: #fff8b7;
      font-family: Ubuntu-bold;
      font-size: 12px;
    }

    .text-green {
      td {
        .highlightable {
          color: $highlight-green;
        }
      }
    }
  }
  #table-pagination {
    bottom: 0;
    right: 0;
  }
}
</style>
